<template>
  <div class="privacy-policy">
    <div class="privacy-policy__container _container">
      <div class="privacy-policy__wrapper">
        <div class="privacy-policy__header">
          <h2 class="privacy-policy__title title">
            {{ $t("privacy_policy.title") }}
          </h2>
        </div>
        <div class="privacy-policy__body">
          <div
            class="privacy-policy__block"
            v-for="item of $t('privacy_policy.items')"
            :key="item.id"
          >
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              {{ item.title }}
            </h6>
            <div v-html="item.text"></div>
          </div>
        </div>
      </div>
      <div class="cookie__wrapper">
        <div class="cookie__header">
          <h2 class="cookie__title title">
            {{ $t("cookie.title") }}
          </h2>
        </div>
        <div class="cookie__body">
          <div
            class="cookie__block"
            v-for="item of $t('cookie.items')"
            :key="item.id"
          >
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              {{ item.title }}
            </h6>
            <div v-html="item.text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style lang="scss">
.cookie__wrapper {
  padding-top: 50px;
  @include for-tablet-portrait-up {
    padding-top: 70px;
  }
  @include for-desktop-up {
    padding-top: 60px;
  }
}
.privacy-policy,
.cookie {
  font-family: "Manrope", sans-serif;
  padding: 110px 10px 50px 10px;
  width: 100%;
  @include for-tablet-portrait-up {
    padding: 132px 15px 60px 15px;
  }
  @include for-tablet-landscape-up {
    padding: 142px 16px 70px 16px;
  }
  @include for-desktop-up {
    padding: 132px 0 106px 0;
  }
  &__title {
    font-family: Merriweather;
    @include H4-semi-bold();
    padding-bottom: 40px;
    color: $main-color;
    @include for-tablet-landscape-up {
      @include H2-semi-bold();
    }
    @include for-desktop-up {
      @include H1-semi-bold();
      padding-bottom: 60px;
    }
  }
  &__subtitle {
    font-family: Merriweather;
    color: $super-dark-gray-color;
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include P1-bold();
    @include for-tablet-portrait-up {
      @include P1-bold();
      margin-bottom: 16px;
    }
    @include for-tablet-landscape-up {
      @include H5-bold();
      margin-bottom: 11px;
    }
    @include for-desktop-up {
      @include H5-bold();
    }
    .vertical-line {
      background: $main-color;
      width: 4px;
      height: 30px;
      border-radius: 8px;
      margin-right: 8px;
      @include for-tablet-portrait-up {
        height: 34px;
        margin-right: 10px;
      }
      @include for-tablet-landscape-up {
        height: 42px;
      }
    }
  }
  &__block + &__block {
    margin-top: 30px;
  }
  &__block {
    div {
      p {
        @include P4();
        @include for-tablet-landscape-up {
          @include P3();
        }
      }
      p + p {
        margin-top: 8px;
        @include for-tablet-portrait-up {
          margin-top: 10px;
        }
      }
      ul {
        list-style-type: disc !important;
        list-style-position: outside !important;
        color: $main-color;
        padding: 0 0 0 17px !important;
        @include P4();
        @include for-tablet-landscape-up {
          @include P3();
        }
        li + li {
          margin-top: 8px;
          @include for-tablet-portrait-up {
            margin-top: 10px;
          }
        }
        li {
          span {
            color: $super-dark-gray-color;
          }
        }
      }
    }
  }
  &__email {
    color: $main-color;
    font-weight: 600;
  }
  a {
    color: $super-dark-gray-color;
    &:hover {
      cursor: pointer;
    }
  }
}
.privacy-policy__wrapper {
  display: flex;
  flex-direction: column;
}
</style>
